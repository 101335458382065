import * as Sentry from "@sentry/gatsby";
import { Integrations } from "@sentry/tracing";

/**
 * There are some errors that we want to ignore in Sentry.
 * e.g. "ReferenceError: Can't find variable: offset" comes from the Just Uno source code
 * and it's not something we can fix.
 * So, we can include these patterns in the IGNORE_ERROR_PATTERNS array.
 *
 * "ReferenceError: Can't find variable: offset": https://ana-luisa.sentry.io/issues/3727789667/?project=6400326
 */

const IGNORE_ERROR_PATTERNS = [
  /^ReferenceError: Can't find variable: offset$/, // just uno
  /^Can't load flickering experiments - missing cookie.$/, // exponea
];

Sentry.init({
  dsn: "https://95602ba9544047b1a58e36e77ac9831d@o195067.ingest.sentry.io/6400326",
  sampleRate: 0.85,
  tracesSampleRate: 0.025,
  release: process.env.GATSBY_COMMIT_REF,
  environment: process.env.GATSBY_SENTRY_ENV,
  transportOptions: { fetchOptions: { keepalive: false } },
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        /^http:\/\/localhost/,
        /^https:\/\/www.analuisa.com/,
        /^https:\/\/analuisa.com/,
        /^https:\/\/checkout.analuisa.com/,
        /^https:\/\/store.analuisa.com/,
      ],
    }),
  ],
  ignoreErrors: IGNORE_ERROR_PATTERNS,
  enabled: process.env.NODE_ENV === "production",
});
